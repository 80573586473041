
import HeaderComponent from "./components/Header.vue";
import FooterComponent from "./components/Footer.vue";
import Maintenance from "@hd2/common/src/views/Maintenance.vue";
import { defineComponent, computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import plPL from "ant-design-vue/es/locale/pl_PL";
import "moment/dist/locale/pl";
import moment from "moment";
import { useRouter } from "vue-router";
import { useStore } from "./store";
import { AxiosStatic } from "axios";
import { useKeycloak } from "./composable/useKeycloak";

moment.locale("pl");

export const AppComponent = defineComponent({
  components: {
    Maintenance,
    "app-header": HeaderComponent,
    "app-footer": FooterComponent,
  },
  setup() {
    const { createKeycloak, isAuthenticated } = useKeycloak();
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    //TODO !!!
    const maintenance = inject("maintenance");
    const loading = ref(!isAuthenticated);
    const kcAuth = inject("kcAuth");
    const authRealm = inject("kcAuthRealm");
    const authClientId = inject("kcAuthClientId");


    createKeycloak(
      {
        url: kcAuth + "/auth",
        realm: authRealm as string,
        clientId: authClientId as string,
      },
      http,
      t,
      store
    );

    return {
      maintenance: computed(() => store.state.runtimeConfig.maintenance),
      t,
      plPL,
      routerPath: computed(() => router.currentRoute.value.name),
      loading,
    };
  },
});
export default AppComponent;
