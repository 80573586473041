import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_maintenance = _resolveComponent("maintenance")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, { locale: _ctx.plPL }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.loading) + " ", 1 /* TEXT */),
      (_ctx.maintenance !== true && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_layout_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_app_header)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_a_layout_content, {
        class: _normalizeClass([
        { 'ant-layout-content--maintenance': _ctx.maintenance },
        { 'ant-layout-content--loading': !_ctx.maintenance && _ctx.loading },
      ])
      }, {
        default: _withCtx(() => [
          (!_ctx.loading && _ctx.maintenance !== true)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : _createCommentVNode("v-if", true),
          (_ctx.maintenance === true)
            ? (_openBlock(), _createBlock(_component_maintenance, {
                key: 1,
                title: _ctx.t('MAINTENANCE.TITLE'),
                description: _ctx.t('MAINTENANCE.DESCRIPTION')
              }, null, 8 /* PROPS */, ["title", "description"]))
            : _createCommentVNode("v-if", true),
          (_ctx.loading && !_ctx.maintenance)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, "loading"))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["class"]),
      (_ctx.maintenance !== true && _ctx.routerPath !== 'Consultation')
        ? (_openBlock(), _createBlock(_component_a_layout_footer, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_app_footer)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}