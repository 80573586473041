
import {
  DownOutlined,
  MenuOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, inject, ref, computed } from "vue";
import { useStore } from "../store";
import { AxiosStatic } from "axios";
import { useI18n } from "vue-i18n";
import { notification } from "ant-design-vue";
import { usePermissions } from "../composable/usePermissions";
import { actions } from "../utils/const";
import { useKeycloak } from "../composable/useKeycloak";

export const HeaderComponent = defineComponent({
  components: {
    DownOutlined,
    MenuOutlined,
    ArrowLeftOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const http = inject("http") as AxiosStatic;

    const { t } = useI18n();
    const { hasPermission } = usePermissions();
    const { logoutUser, isAuthenticated } = useKeycloak();

    const visibleMobileMenu = ref(false);

    const authUrl = store.state.runtimeConfig.authUrl ?? "";

    const changeRoute = (path: string) => {
      visibleMobileMenu.value = false;
      router.push({ path });
    };

    const logout = async () => {
      try {
        store.commit("resetUser");
        visibleMobileMenu.value = false;
        http.defaults.headers.common = {};
        logoutUser();
        if (authUrl) {
          window.location.replace(authUrl);
        }
      } catch {
        notification.open({
          message: t("ERROR.4867"),
          class: "error",
        });
      }
    };

    return {
      logout,
      changeRoute,
      visibleMobileMenu,
      hasPermission,
      actions,
      isLoggedIn: isAuthenticated,
      authUrl: store.state.runtimeConfig.authUrl,
      siteUrl: store.state.runtimeConfig.siteUrl,
      typeClass: computed(() =>
        store.state.runtimeConfig.type.toLowerCase().replace("_", "-")
      ),
      route,
      t,
    };
  },
});

export default HeaderComponent;
