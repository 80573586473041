import { createApp } from "vue";
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Table,
  Upload,
  Drawer,
  Skeleton,
  Empty,
  Result,
  Steps,
  ConfigProvider,
  Card,
} from "ant-design-vue";
import Axios from "axios";
import "ant-design-vue/dist/antd.css";
import AppComponent from "./App.vue";
import router from "./router";
import { makeServer } from "./mock/server";
import { setupI18n } from "./i18n";
import { store, key } from "./store";
import { RuntimeConfig } from "../types";
import { setMeta } from "./utils";
import VueGtag from "vue-gtag";
import { allowedGATrackingPage } from "./utils/const";
import Maska from "maska";
import { vueKeycloak } from "@baloise/vue-keycloak";

export const axios = Axios;

(async () => {
  const runtimeConfig: RuntimeConfig = await fetch("/config.json").then(
    (response) => response.json()
  );

  store.commit("setRuntimeConfig", {
    ...runtimeConfig,
    mode: process.env.NODE_ENV,
  });
  setMeta();

  if (runtimeConfig.mockup) {
    makeServer({
      apiUrl: runtimeConfig.apiUrl,
      authUrl: runtimeConfig.authUrl,
    });
  }

  Axios.defaults.baseURL = runtimeConfig.apiUrl;

  const i18n = setupI18n();

  const app = createApp(AppComponent);

  app.provide("http", Axios);
  app.provide("maintenance", !!runtimeConfig.maintenance);
  app.provide("kcAuth", runtimeConfig.kcAuth);
  app.provide("kcAuthRealm", runtimeConfig.kcAuthRealm);
  app.provide("kcAuthClientId", runtimeConfig.kcAuthClientId);

  app.use(store, key);
  app.use(router);
  app.use(i18n);
  app.use(Alert);
  app.use(Breadcrumb);
  app.use(Button);
  app.use(Checkbox);
  app.use(Col);
  app.use(DatePicker);
  app.use(ConfigProvider);
  app.use(Divider);
  app.use(Dropdown);
  app.use(Form);
  app.use(Input);
  app.use(InputNumber);
  app.use(Layout);
  app.use(List);
  app.use(Menu);
  app.use(Modal);
  app.use(Pagination);
  app.use(Popconfirm);
  app.use(Popover);
  app.use(Progress);
  app.use(Radio);
  app.use(Row);
  app.use(Select);
  app.use(Slider);
  app.use(Spin);
  app.use(Table);
  app.use(Upload);
  app.use(Drawer);
  app.use(Skeleton);
  app.use(Empty);
  app.use(Steps);
  app.use(Result);
  app.use(Card);
  app.use(Maska);
  if (runtimeConfig.GAId) {
    app.use(
      VueGtag,
      {
        config: { id: runtimeConfig.GAId },
        pageTrackerExcludedRoutes: await allowedGATrackingPage(
          runtimeConfig.type
        ),
      },
      router
    );
  }

  app.mount("#app");
})();
