import { User, UserProfile, UserState } from "../../types";
import { decode } from "js-base64";
import { store } from "../store";

// export const getUserState = (data: User, profile?: UserProfile): UserState => {
//   const tokenData = JSON.parse(decode(data.access_token.split(".")[1]));

//   switch (tokenData.iss) {
//     case "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_HFzSR1y6F": {
//       return {
//         accessToken: data.access_token,
//         refreshToken: data.refresh_token,
//         expiresOn: Date.now() + 9999 * 1000,
//         expiresTimeout: 9999 * 1000,
//         roles: ["PATIENT"],
//         agreements: true,
//       };
//     }
//     default: {
//       return {
//         accessToken: data.access_token,
//         refreshToken: data.refresh_token,
//         expiresOn: Date.now() + (data.refresh_expires_in || 0) * 1000,
//         expiresTimeout: (data.refresh_expires_in || 0) * 1000,
//         roles: tokenData.realm_access.roles,
//         agreements: profile?.termsAgreement ?? false,
//       };
//     }
//   }
// };

export const setMeta = () => {
  let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  switch (store.state.runtimeConfig.type) {
    case "GENERAL": {
      document.title = "HomeDoctor - Portal Pacjenta";
      link.href = "favicon.ico";
      break;
    }
    case "PZU": {
      document.title = "HomeDoctor - PZU Zdrowie";
      link.href = "favicon-pzu.ico";
      break;
    }
    case "POLMED": {
      document.title = "HomeDoctor - Polmed";
      link.href = "favicon-polmed.ico";
      break;
    }
    case "MONDIAL": {
      document.title = "HomeDoctor - Mondial";
      link.href = "favicon-mondial.ico";
      break;
    }
    case "ALLIANZ": {
      document.title = "HomeDoctor - Allianz";
      link.href = "favicon-allianz.ico";
      break;
    }
    case "EUROP_ASSISTANCE": {
      document.title = "HomeDoctor - Europ Assistance";
      link.href = "favicon-europ-assistance.ico";
      break;
    }
    case "CMP": {
      document.title = "HomeDoctor - Centrum Medyczne";
      link.href = "favicon-cmp.ico";
      break;
    }
    case "GLOBAL_ASSISTANCE": {
      document.title = "HomeDoctor - Global assistance";
      link.href = "favicon-global-assistance.ico";
      break;
    }
    case "SIGNAL_IDUNA": {
      document.title = "HomeDoctor - Signal Iduna";
      link.href = "favicon-signal-iduna.ico";
      break;
    }
    case "COMPENSA": {
      document.title = "HomeDoctor - Compensa";
      link.href = "favicon-compensa.ico";
      break;
    }
    case "SALTUS": {
      document.title = "HomeDoctor - Saltus";
      link.href = "favicon-saltus.ico";
      break;
    }
    case "TU_ZDROWIE": {
      document.title = "HomeDoctor - Tu Zdrowie";
      link.href = "favicon-tu-zdrowie.ico";
      break;
    }
    case "AXA": {
      document.title = "HomeDoctor - AXA";
      link.href = "favicon-axa.ico";
      break;
    }
    case "GENERALI": {
      document.title = "HomeDoctor - Generali";
      link.href = "favicon-generali.ico";
      break;
    }
    case "APRIL": {
      document.title = "HomeDoctor - April";
      link.href = "favicon-april.ico";
      break;
    }
    case "WARTA": {
      document.title = "HomeDoctor - Warta";
      link.href = "favicon-warta.ico";
      break;
    }
    case "INTER": {
      document.title = "HomeDoctor - INTER Polska";
      link.href = "favicon.ico";
      break;
    }
    case "NATIONALE_NEDERLANDEN": {
      document.title = "HomeDoctor - Nationale Nederlanden";
      link.href = "favicon-nationale-nederlanden.ico";
      break;
    }
    case "TU_EUROPA": {
      document.title = "HomeDoctor - TU Europa";
      link.href = "favicon-tu-europa.ico";
      break;
    }
    case "TUZ_UBEZPIECZENIA": {
      document.title = "HomeDoctor - TUZ Ubezpieczenia";
      link.href = "favicon-tuz-ubezpieczenia.ico";
      break;
    }
    case "MEEDY": {
      document.title = "HomeDoctor - Meedy";
      link.href = "favicon.ico";
      break;
    }
    case "LUXMED": {
      document.title = "HomeDoctor - Grupa LUX MED";
      link.href = "favicon.ico";
      break;
    }
    default: {
      document.title = "HomeDoctor - Portal Pacjenta";
      link.href = "favicon.ico";
    }
  }
};
