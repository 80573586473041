import { createStore, useStore as baseUseStore, Store } from "vuex";
import { InjectionKey } from "vue";
import { OrderExt, UserState, RuntimeConfigExt, NfzState } from "../../types";

export interface State {
  user: UserState;
  order: OrderExt;
  runtimeConfig: RuntimeConfigExt;
  nfz: NfzState;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const initialUserState: UserState = {
  roles: [],
  agreements: false,
};
export const initialOrderState: OrderExt = {
  id: 0,
  doctor: {
    id: 0,
    firstName: "",
    lastName: "",
    specializations: [{ id: 0, name: "" }],
  },
  start: new Date().toISOString(),
  end: new Date().toISOString(),
  price: 0,
  specializationId: 0,
  country: { id: 0, name: "" },
  adultsCount: 0,
  childrenCount: 0,
  patients: [],
  appointmentType: "CALL",
  address: {
    city: "",
    postCode: "",
    streetName: "",
    streetNumber: "",
    country: "",
    flatNumber: "",
    additionalInfo: "",
  },
  email: "",
  phoneNumber: {
    number: "",
    prefix: "",
    pattern: "",
  },
  setDefaultAddress: false,
  forNfz: false,
};

export const initialNfzState: NfzState = {
  nfzActive: false,
};

export const store = createStore<State>({
  state: {
    user: initialUserState,
    order: JSON.parse(
      sessionStorage.getItem("order") || JSON.stringify(initialOrderState)
    ),
    runtimeConfig: {
      apiUrl: "/",
      authUrl: "",
      siteUrl: "",
      type: "GENERAL",
      recapthaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
      cognitoClientId: "",
      thankuPage: "",
      mockup: true,
      mode: "development",
      maintenance: false,
      allowedIframeDataSources: [],
      kcAuthRealm: "",
      kcAuthClientId: "",
      kcAuth: "",
    },
    nfz: JSON.parse(
      sessionStorage.getItem("nfz") || JSON.stringify(initialNfzState)
    ),
  },
  getters: {
    acceptedAgreements(state) {
      return state.user.agreements;
    },
    isNfzActive(state) {
      return state.nfz.nfzActive;
    },
    isForNfz(state) {
      return state.order.forNfz;
    },
  },
  mutations: {
    updateUser(state, data: Partial<UserState>) {
      state.user = { ...state.user, ...data };
      sessionStorage.setItem("user", JSON.stringify(state.user));
    },
    setUser(state, data: UserState) {
      state.user = data;
      sessionStorage.setItem("user", JSON.stringify(data));
    },
    setOrder(state, data: OrderExt) {
      state.order = data;
      sessionStorage.setItem("order", JSON.stringify(data));
    },
    setRuntimeConfig(state, data: RuntimeConfigExt) {
      state.runtimeConfig = data;
    },
    updateRuntimeConfig(state, data: RuntimeConfigExt) {
      state.runtimeConfig = { ...state.runtimeConfig, ...data };
    },
    clearOrder(state) {
      state.order = { ...initialOrderState };
      sessionStorage.setItem("order", JSON.stringify(state.order));
    },
    resetUser(state) {
      state.user = initialUserState;
      state.nfz = initialNfzState;
      sessionStorage.setItem("user", JSON.stringify(state.user));
      sessionStorage.removeItem("nfz");
    },
    setNfzActive(state, data: NfzState["nfzActive"]) {
      state.nfz.nfzActive = data;
      sessionStorage.setItem("nfz", JSON.stringify(state.nfz));
    },
    setUserAgreements(state, data: UserState["agreements"]) {
      state.user.agreements = data;
    },
    setForNfz(state, data: OrderExt["forNfz"]) {
      state.order.forNfz = data;
      sessionStorage.setItem("order", JSON.stringify(state.order));
    },
  },
});

export function useStore() {
  return baseUseStore(key);
}
