import { SlotConfig } from "../../types";
import CallIcon from "../assets/images/icons/call.svg?inline";
import MedicalFacilityIcon from "../assets/images/icons/medical-facility.svg?inline";
import HouseIcon from "../assets/images/icons/house.svg?inline";
import ChatIcon from "../assets/images/icons/chat.svg?inline";
import VideoIcon from "../assets/images/icons/video.svg?inline";

export const defaultPhonePrefix = "+48";
export const slotsConfig: SlotConfig = {
  intervalSize: (type, specializationId) => {
    let intervalSize = 0;
    if (type === "HOUSE") {
      intervalSize = 120;
    } else {
      switch (specializationId) {
        case 29:
        case 5: {
          intervalSize = 60;
          break;
        }
        default: {
          intervalSize = 30;
        }
      }
    }
    return intervalSize;
  },
  from: "08:00:00.00000",
  to: "22:00:00.00000",
};

export const actions = {
  SHOW_MAKE_APPOINTMENT: "SHOW_MAKE_APPOINTMENT",
  SHOW_PROFILE: "SHOW_PROFILE",
  SHOW_VISITS: "SHOW_VISITS",
  SHOW_VISIT: "SHOW_VISIT",
  SHOW_ORDER: "SHOW_ORDER",
  SHOW_ORDER_PERSONAL: "SHOW_ORDER_PERSONAL",
  SHOW_ORDER_SYMPTOMS: "SHOW_ORDER_SYMPTOMS",
  SHOW_ORDER_PAYMENT: "SHOW_ORDER_PAYMENT",
  SHOW_APPOINTMENT_SUCCESS: "SHOW_APPOINTMENT_SUCCESS",
  SHOW_APPOINTMENT_FAILED: "SHOW_APPOINTMENT_FAILED",
  SHOW_APPOINTMENT_RESULT: "SHOW_APPOINTMENT_RESULT",
  SHOW_NO_DOCTORS: "SHOW_NO_DOCTORS",
  SHOW_APPOINTMENT_TYPES: "SHOW_APPOINTMENT_TYPES",
  SHOW_TRADE_AGREEMENT: "SHOW_TRADE_AGREEMENT",
  SHOW_MARKETING_AGREEMENT: "SHOW_MARKETING_AGREEMENT",
  EDIT_PHONE_NUMBER: "EDIT_PHONE_NUMBER",
  EDIT_EMAIL: "EDIT_EMAIL",
  SHOW_OTHER_SLOTS: "SHOW_OTHER_SLOTS",
  DISABLE_MAKE_APPOITNMENT_COUNTRY: "DISABLE_MAKE_APPOITNMENT_COUNTRY",
  DISABLE_MAKE_APPOITNMENT_LANGUAGE: "DISABLE_MAKE_APPOITNMENT_LANGUAGE",
  SHOW_ORDER_STEPS: "SHOW_ORDER_STEPS",
  SHOW_PRICE: "SHOW_PRICE",
  SHOW_SYMPTOM_CHECKER: "SHOW_SYMPTOM_CHECKER",
  ORDER_CHANGE_ID: "ORDER_CHANGE_ID",
  SHOW_COMMUNICATION_TYPE_CALL: "SHOW_COMMUNICATION_TYPE_CALL",
  SHOW_COMMUNICATION_TYPE_CHAT: "SHOW_COMMUNICATION_TYPE_CHAT",
  SHOW_COMMUNICATION_TYPE_VIDEO: "SHOW_COMMUNICATION_TYPE_VIDEO",
  CHECK_SYMPTOM_FORM: "CHECK_SYMPTOM_FORM",
  SHOW_CONSULTATION: "SHOW_CONSULTATION",
  REGISTRATION: "REGISTRATION",
  SHOW_POWEREDBY: "SHOW_POWEREDBY",
  SHOW_NOTIFICATIONS: "SHOW_NOTIFICATIONS",
  SHOW_NFZ: "SHOW_NFZ",
};

export const roles = {
  PATIENT: "PATIENT",
  DOCTOR: "DOCTOR",
  GENERAL: "GENERAL",
  PZU: "PZU",
  POLMED: "POLMED",
  MONDIAL: "MONDIAL",
  ALLIANZ: "ALLIANZ",
  EUROP_ASSISTANCE: "EUROP_ASSISTANCE",
  CMP: "CMP",
  GLOBAL_ASSISTANCE: "GLOBAL_ASSISTANCE",
  SIGNAL_IDUNA: "SIGNAL_IDUNA",
  COMPENSA: "COMPENSA",
  SALTUS: "SALTUS",
  TU_ZDROWIE: "TU_ZDROWIE",
  AXA: "AXA",
  GENERALI: "GENERALI",
  APRIL: "APRIL",
  WARTA: "WARTA",
  INTER: "INTER",
  NATIONALE_NEDERLANDEN: "NATIONALE_NEDERLANDEN",
  TU_EUROPA: "TU_EUROPA",
  TUZ_UBEZPIECZENIA: "TUZ_UBEZPIECZENIA",
  WELBI: "WELBI",
  MEEDY: "MEEDY",
  LUXMED: "LUXMED",
};

export const allowedGATrackingPage = async (
  type: string
): Promise<string[]> => {
  const { routes } = await import("../router");
  switch (type) {
    case "WELBI": {
      return routes
        .map((route) => route.name)
        .filter(
          (route) =>
            ![
              "Order",
              "OrderPersonal",
              "OrderSymptoms",
              "OrderPayment",
              "AppointmentResult",
              "AppointmentFailed",
              "AppointmentSuccess",
            ].includes(route)
        );
    }
    default: {
      return routes.map((route) => route.name);
    }
  }
};

export const blockedSlotsForSpecializations = new Map([
  [roles.INTER, [45, 44]],
]);

export const notificationsList = {
  SESSION_TIMEOUT: "SESSION_TIMEOUT",
};

export const visitTypesIconMap = new Map([
  ["HOUSE", HouseIcon],
  ["CALL", CallIcon],
  ["STATIONARY", MedicalFacilityIcon],
  ["CHAT", ChatIcon],
  ["VIDEO", VideoIcon],
]);
