import { AxiosStatic } from "axios";
import Keycloak, { KeycloakInstance } from "keycloak-js";
import { ref, Ref, readonly } from "vue";
import { initialUserState, State } from "../store";
import { Store } from "vuex";
import { notification } from "ant-design-vue";
import { UserState } from "../../types";

interface KcConfig {
  url: string;
  realm: string;
  clientId: string;
}

const roles = {
  PATIENT: "PATIENT",
  DOCTOR: "DOCTOR",
};

const _keycloak: Ref<KeycloakInstance | undefined> = ref(undefined);
const _http: Ref<AxiosStatic | undefined> = ref(undefined);
const _t: any = ref({});
const _store: Ref<Store<State> | undefined> = ref(undefined);
const _isAuthenticated = ref(false);
const _kcInitialized = ref(false);

export const useKeycloak = () => {
  const createKeycloak = (
    kcConfig: KcConfig,
    http: AxiosStatic,
    t: any,
    store: Store<State>
  ) => {
    _http.value = http;
    _t.value = t;
    _store.value = store;
    _keycloak.value = Keycloak(kcConfig);
  };

  const setupUser = async (
    userState: UserState = initialUserState,
    keycloak: KeycloakInstance,
    http: AxiosStatic,
    store: Store<State>
  ) => {
    try {
      const profileRes = await http.get("patient-portal/api/patient-profile");
      store.commit("setUser", userState);
      store.commit("setNfzActive", profileRes.data.nfzActive);
      store.commit("setUserAgreements", profileRes.data.termsAgreement);
    } catch {
      notification.open({
        message: _t.value("ERROR.4312"),
        class: "error",
      });
      keycloak.logout();
    }
  };

  const updateToken = (token: string, http: AxiosStatic) => {
    http.defaults.headers.Authorization = `Bearer ${token}`;
  };

  const onTokenRefresh = async (
    keycloak: KeycloakInstance,
    http: AxiosStatic
  ) => {
    keycloak.onTokenExpired = () => {
      onTokenRefresh(keycloak, http);
    };
    await keycloak
      .updateToken(5)
      .then(async () => {
        updateToken(keycloak.token as string, http);
      })
      .catch(() => {
        keycloak.logout();
        notification.open({
          message: _t.value("ERROR.4311"),
          class: "error",
        });
      });
  };

  const initKeycloak = async () => {
    if (_keycloak.value && _http.value) {
      _keycloak.value.onTokenExpired = () => {
        onTokenRefresh(
          _keycloak.value as KeycloakInstance,
          _http.value as AxiosStatic
        );
      };
      return _keycloak.value
        .init({
          onLoad: "check-sso",
          enableLogging: true,
        })
        .then(async (authenticated: boolean) => {
          if (authenticated) {
            updateToken(
              _keycloak.value?.token as string,
              _http.value as AxiosStatic
            );
            await setupUser(
              {
                ...initialUserState,
                roles: _keycloak.value?.tokenParsed?.realm_access?.roles.filter(
                  (rule: string) => {
                    return rule == roles.PATIENT || rule == roles.DOCTOR;
                  }
                ) as string[],
              },
              _keycloak.value as KeycloakInstance,
              _http.value as AxiosStatic,
              _store.value as Store<State>
            );
            _isAuthenticated.value = authenticated;
            _kcInitialized.value = true;
          }

          return authenticated;
        })
        .catch(() => {
          notification.open({
            message: _t.value("ERROR.4311"),
            class: "error",
          });
          _isAuthenticated.value = false;
          _kcInitialized.value = true;
        });
    } else {
      notification.open({
        message: _t.value("ERROR.4982"),
        class: "error",
      });
    }
  };

  const loginUser = async () => await _keycloak.value?.login();
  const logoutUser = async () => await _keycloak.value?.logout();

  return {
    initKeycloak,
    createKeycloak,
    loginUser,
    logoutUser,
    isAuthenticated: readonly(_isAuthenticated),
    kcInitialized: readonly(_kcInitialized),
  };
};
